<template>
  <div class="lesson-box">
    <!-- Displaying properties of teacher -->
    <h2>{{ lesson.name }}</h2>
    <p>{{ lesson.description }}</p>
    <LinguaButton @click="selectLesson">
      {{ mode == "continue" ? $t("lesson.continue") : $t("lesson.select") }}
    </LinguaButton>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import LinguaButton from "../components/LinguaButton.vue";

const props = defineProps({
  lesson: {
    type: Object,
    required: true,
  },
  mode: {
    type: String,
    required: false,
  },
});
const router = useRouter();
const emit = defineEmits(["show-lesson-details"]);

async function selectLesson() {
  if (props.mode == "continue") {
    router.push("/lesson", {
      props: { lesson: () => props.lesson, teacher: () => props.teacher },
    });
  } else {
    emit("show-lesson-details", props.lesson);
  }
}
</script>

<style>
/* Styling for the teacher box */
.lesson-box {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
}
</style>
