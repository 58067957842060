<template>
  <div id="app">
    <div class="error-wrapper" v-if="error">
      <div class="error">{{ error }}</div>
    </div>
    <div class="lesson-container">
      <div class="speak-panel" v-if="lesson">
        <div class="left-panel">
          <Avatar
            ref="avatarComponent"
            :teacher="teacher"
            :variant="'in-lesson'"
            @speaking-stopped="speakingStopped"
          ></Avatar>
          <LessonProgress
            v-if="isMobileScreen"
            :steps="lesson.steps"
            :current-step="currentStep.id"
            @move-to-step="moveToStep"
          ></LessonProgress>
        </div>
        <div class="right-panel">
          <div class="buttons-container">
            <LinguaButton
              @click="showStopLessonPopup = true"
              :variant="'clean'"
            >
              <svg
                stroke="#555"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="15"
                  cy="15"
                  r="11.25"
                  fill="none"
                  stroke-width="2"
                />
                <line x1="10" y1="10" x2="20" y2="20" stroke-width="2" />
                <line x1="20" y1="10" x2="10" y2="20" stroke-width="2" />
              </svg>
            </LinguaButton>
          </div>
          <div ref="chatContainer" class="chat-container">
            <div
              v-for="message in messages"
              :key="message.id"
              :class="`message ${message.type}`"
            >
              <div v-if="!message.loading">{{ message.text }}</div>
              <div v-else class="spinner"></div>
            </div>
            <div ref="endOfMessages"></div>
          </div>
          <div class="input-container">
            <div class="button-container">
              <ButtonHint message="Click here to talk" :duration="3000" />
              <LinguaButton
                @click="toggleRecording"
                :variant="'clean'"
                :disabled="isRecordingUnavailable"
              >
                <svg
                  v-if="isRecording"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="none"
                  stroke="red"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <!-- Microphone body -->
                  <rect x="9" y="2" width="6" height="12" rx="3"></rect>

                  <!-- Microphone base -->
                  <path d="M5 10v2a7 7 0 0 0 14 0v-2"></path>

                  <!-- Stand -->
                  <line x1="12" y1="22" x2="12" y2="16"></line>

                  <!-- Stand base -->
                  <line x1="8" y1="22" x2="16" y2="22"></line>
                </svg>
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <!-- Microphone body -->
                  <rect x="9" y="2" width="6" height="12" rx="3"></rect>

                  <!-- Microphone base -->
                  <path d="M5 10v2a7 7 0 0 0 14 0v-2"></path>

                  <!-- Stand -->
                  <line x1="12" y1="22" x2="12" y2="16"></line>

                  <!-- Stand base -->
                  <line x1="8" y1="22" x2="16" y2="22"></line>
                </svg>
              </LinguaButton>
            </div>
            <textarea
              ref="autoTextarea"
              class="type-message-box"
              @keydown.enter.prevent="sendMessage"
              @input="handleUserInput"
              v-model="userInput"
              :rows="1"
              :disabled="isDisabledInput"
              :placeholder="
                isRecording
                  ? $t('lesson.speakOrTypeMessage')
                  : $t('lesson.typeMessage')
              "
            ></textarea>
          </div>

          <LinguaButton @click="sendMessage">{{
            $t("lesson.send")
          }}</LinguaButton>
        </div>
        <LessonProgress
          v-if="!isMobileScreen"
          :steps="lesson.steps"
          :current-step="currentStep.id"
          @move-to-step="moveToStep"
        ></LessonProgress>
      </div>
      <button @click="showDevPanel = !showDevPanel" class="devOptionsButton">
        {{ $t("lesson.devOptions") }}
      </button>
      <div class="import-panel" v-if="showDevPanel">
        <textarea
          class="paste-text"
          v-model="jsonInput"
          :disabled="!isImportEnabled"
          :placeholder="$t('lesson.pasteJSON')"
        ></textarea>
        <LinguaButton @click="importLesson" class="button">Import</LinguaButton>
      </div>
    </div>
    <transition name="fade">
      <div>
        <StopLessonPopup
          v-if="showStopLessonPopup"
          @close="closePopup"
          @close-lesson="closeLesson"
          @lesson-stopped="lessonStopped"
        />
      </div>
    </transition>
    <transition name="fade">
      <div>
        <MultiStepRating
          v-if="showRatingPopup"
          :steps="ratingSteps"
          @close="closeRatingPopup"
          @close-lesson="closeLesson"
          :lesson="lesson"
        />
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onUnmounted, nextTick, reactive } from "vue";
import { useStore } from "vuex";
import { processResponseWithAudio, getAudioUrl } from "../utils/audioResponse";

import axios from "axios";
import { RealtimeTranscriber } from "assemblyai";
import { createMicrophone } from "../utils/microphone";
import LinguaButton from "../components/LinguaButton.vue";
import Avatar from "../components/Avatar.vue";
import LessonProgress from "../components/LessonProgress.vue";
import MultiStepRating from "../components/MultiStepRating.vue";
import StopLessonPopup from "../components/StopLessonPopup.vue";
import ButtonHint from "../components/ButtonHint.vue";
import chatService from "@/services/chatService";
import lessonService from "@/services/lessonService";
import router from "@/router/router";
// import teacherService from "@/services/teacherService";

const autoTextarea = ref(null);
const lesson = ref(null);
const teacher = ref(null);
const currentStep = ref(null);
const avatarComponent = ref(null);
const endOfMessages = ref(null);
const isTextToSpeechEnabled = ref(false);
const isImportEnabled = ref(true);
const nextId = ref(0);
const messages = ref([]);
const isDisabledInput = ref(false);
const userInput = ref("");
const isLessonFinished = ref(false);
const userInputPreviousSentences = ref("");
const jsonInput = ref("");
const error = ref("");
const isRecording = ref(false);
const showStopLessonPopup = ref(false);
const showRatingPopup = ref(false);
let isRecordingUnavailable = ref(true);

const transcriber = ref(null);
const microphone = ref(null);
const showDevPanel = ref(false);
const store = useStore();
const isMobileScreen = ref(false);

const ratingSteps = [
  { question: "How satisfied are you with the lesson?" },
  { question: "How do you rate the pace of the lesson?" },
  { question: "How do you rate the lesson topic?" },
  { question: "How do you rate the teacher's attitude?" },
  { question: "Any additional remarks?" },
];

watch(
  messages,
  () => {
    nextTick(() => {
      scrollToBottom();
    });
  },
  { deep: true }
);

const handleUserInput = (event) => {
  const newInput = event.target.value;

  userInputPreviousSentences.value = newInput;
  adjustTextareaHeight();
};

const adjustTextareaHeight = () => {
  nextTick(() => {
    const textarea = autoTextarea.value;
    if (textarea) {
      textarea.style.height = "auto"; // Reset height to auto to recalculate
      const computed = window.getComputedStyle(textarea);

      // Calculate the height
      const height =
        textarea.scrollHeight +
        parseFloat(computed.borderTopWidth) +
        parseFloat(computed.borderBottomWidth);

      // Set the new height
      textarea.style.height = `${height}px`;
    }
  });
};

onMounted(async () => {
  checkScreenSize();
  window.addEventListener("resize", checkScreenSize);
  isTextToSpeechEnabled.value = store.state.speakerOn;
  await getCurrentLesson();
  if (isLastMessageTypeBot()) {
    // say last
  } else {
    sendMessage();
  }
  runAssembly();
});

onUnmounted(() => {
  // Clean up the event listener on component unmount
  window.removeEventListener("resize", checkScreenSize);
});

const checkScreenSize = () => {
  // Adjust the media query as per your mobile breakpoint (here: 768px)
  isMobileScreen.value = window.matchMedia("(max-width: 600px)").matches;
};

const speakingStopped = () => {
  if (isLessonFinished.value) {
    showRatingPopup.value = true;
  }
};

const scrollToBottom = () => {
  if (endOfMessages.value) {
    endOfMessages.value.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }
};
const toggleRecording = async () => {
  if (!isRecording.value) {
    await startRecording();
    isRecording.value = true;
  } else {
    stopRecording();
    isRecording.value = false;
  }
};

const startRecording = async () => {
  microphone.value = createMicrophone();
  await microphone.value.requestPermission();
  await microphone.value.startRecording((audioData) => {
    transcriber.value.sendAudio(audioData);
  });
};

const stopRecording = () => {
  microphone.value.stopRecording();
};

const moveToStep = async (step) => {
  try {
    let res = await chatService.moveToStep(step);
    return res.data;
  } catch (error) {
    error.value = error;
    isImportEnabled.value = true;
  }
};

const getAssemblyTempKey = async () => {
  try {
    let res = await chatService.getAssemblyTempToken();
    return res.data;
  } catch (error) {
    error.value = error;
    isImportEnabled.value = true;
  }
};

const runAssembly = async () => {
  try {
    const assemblyTempKey = await getAssemblyTempKey();
    transcriber.value = new RealtimeTranscriber({
      token: assemblyTempKey.token,
    });

    transcriber.value.on("open", ({ sessionId }) => {
      console.log(`Session opened with ID: ${sessionId}`);
      isRecordingUnavailable.value = false;
    });
    transcriber.value.on("error", (error) => {
      console.error("Error:", error);
      isRecordingUnavailable.value = true;
    });
    transcriber.value.on("close", (code, reason) => {
      console.log("Session closed:", code, reason);
      isRecordingUnavailable.value = true;
    });
    transcriber.value.on("transcript", async (transcript) => {
      if (!transcript.text) {
        return;
      }
      if (transcript.message_type === "PartialTranscript") {
        console.log("Partial:", transcript.text);
        userInput.value = userInputPreviousSentences.value + transcript.text;
      } else {
        console.log("Final:", transcript.text);
        let text = transcript.text;
        let fullText = userInputPreviousSentences.value + text + " ";
        userInputPreviousSentences.value = fullText;
        userInput.value = userInputPreviousSentences.value;
        try {
          let res = await chatService.transcribeInput(fullText);
          fullText = res.data.text;
          userInputPreviousSentences.value = fullText;
          userInput.value = userInputPreviousSentences.value;
        } catch (e) {
          console.log("ERROR with transcription", e);
        }
      }
      adjustTextareaHeight();
    });
    await transcriber.value.connect();
  } catch (error) {
    console.error(error);
  }
};

const addHistoryMessages = (historyMessages) => {
  historyMessages.forEach((message) => {
    addMessage(message.message, determineMessageType(message.person));
  });
};

const determineMessageType = (person) => {
  switch (person) {
    case "T":
      return "bot";
    case "S":
      return "user";
    default:
      return "moderator";
  }
};

const addMessage = (text, type, loading = false) => {
  let message = { id: nextId.value++, text, type, loading };
  messages.value.push(reactive(message));
  return message;
};

const isLastMessageTypeBot = () => {
  if (messages.value.length === 0) return false;
  const lastMessage = messages.value[messages.value.length - 1];
  return lastMessage.type === "bot";
};

const applyLessonImportToChatWindow = (lessonJSON, stopMarker) => {
  if (lessonJSON && lessonJSON.history && Array.isArray(lessonJSON.history)) {
    for (const item of lessonJSON.history) {
      if (item.message === stopMarker) {
        return;
      }
      let type = determineMessageType(item.person);
      addMessage(item.message, type, false);
    }
  }
};

const getCurrentLesson = async () => {
  // error.value = "";
  try {
    const response = await lessonService.getCurrentLesson();
    const data = await response.data;

    if (data.success) {
      addHistoryMessages(data.data.chatHistory);
      lesson.value = data.data.lesson;
      teacher.value = data.data.teacher;
      currentStep.value = data.data.currentStep;
    } else {
      console.error(`Error ${data.error.code}: ${data.error.message}`);
    }
  } catch (error) {
    // Handle network or other errors
    console.error("An error occurred:", error);
  }
};

const importLesson = async () => {
  try {
    error.value = "";
    isImportEnabled.value = false;
    const jsonData = JSON.parse(jsonInput.value);
    await axios.post(
      `${process.env.VUE_APP_SERVER_URL}/lesson/import`,
      jsonData
    );
    isImportEnabled.value = true;
    messages.value = [];
    applyLessonImportToChatWindow(jsonData, "STOP");
  } catch (error) {
    error.value = error;
    isImportEnabled.value = true;
  }
};

// const resetChat = async () => {
//   try {
//     error.value = "";
//     await axios.post(`${process.env.VUE_APP_SERVER_URL}/lesson/reset`);
//     messages.value = [];
//   } catch (error) {
//     error.value = error;
//   }
// };

const sendMessage = async (event) => {
  try {
    error.value = "";
    if (userInput.value || !event) {
      isDisabledInput.value = true;
      const userInputText = userInput.value.trim() || "";
      if (event) {
        addMessage(userInputText, "user");
      }
      const botMessage = addMessage("", "bot", true);

      try {
        const response = await chatService.sendMessage(
          userInputText,
          isTextToSpeechEnabled.value
        );

        const { responseJson, responseText, buffer, contentLength } =
          await processResponseWithAudio(response);

        botMessage.text = responseJson.chatResponse;
        currentStep.value = responseJson.currentStep;
        botMessage.loading = false;
        userInput.value = "";
        userInputPreviousSentences.value = "";

        await nextTick();
        scrollToBottom();
        const audioUrl = getAudioUrl(buffer, contentLength);
        if (audioUrl) {
          avatarComponent.value.speak(
            responseText,
            audioUrl,
            responseJson.visemes
          );
        }
        if (responseJson.isLessonFinished) {
          isLessonFinished.value = true;
        }
      } catch (error) {
        console.log("ERROR", error);
        botMessage.loading = false;
      }
    }
  } catch (error) {
    error.value = error;
    console.error("Error sending request:", error);
  }

  if (!isLessonFinished.value) {
    isDisabledInput.value = false;
  }
};

const closePopup = async () => {
  showStopLessonPopup.value = false;
};

const closeRatingPopup = async () => {
  showRatingPopup.value = false;
};

const closeLesson = async () => {
  router.push("/lessons");
};

const lessonStopped = async () => {
  showRatingPopup.value = true;
  avatarComponent.value.stopAudio();
};
</script>

<style>
/* Add basic styling */
.chat-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 5px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  margin-bottom: 20px;
  overflow: auto;
  height: 400px;
}

.message {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 12px;
  color: white;
  max-width: 80%;
  margin: 5px;
}

.user {
  background-color: #0b93f6;
  align-self: flex-end;
  margin-left: auto;
}

.bot {
  background-color: #e5e5ea;
  color: black;
  align-self: flex-start;
  margin-right: auto;
}
.moderator {
  background-color: aqua;
  color: black;
  align-self: flex-start;
  margin-right: auto;
}

input {
  padding: 10px;
  margin: 10px;
  border: 1px solid #e1e1e1;
  border-radius: 1px;
}

.spinner {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 1px solid #3498db;
  width: 14px;
  height: 14px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.left-panel {
  display: flex;
  height: 30vh;
  margin: 10px;
}

.paste-text {
  height: 200px;
  width: 100%;
}
.right-panel {
  display: flex;
  flex-direction: column;
  height: 60vh;
  margin: 10px;
}

@media (min-width: 600px) {
  .right-panel {
    width: 50%;
    height: auto;
  }
}

.lesson-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centers items horizontally */
  align-items: center; /* Optional: centers items vertically */
  width: 100%;
  height: 100%;
}
.speak-panel {
  justify-content: center;
  width: 100%;
}

@media (min-width: 600px) {
  .speak-panel {
    display: flex;
  }
}

.import-panel {
  width: 100%;
}

.error {
  color: white;
  max-width: 500px;
  background-color: lightcoral;
  padding-right: 50px;
  padding-left: 50px;
}
.error-wrapper {
  margin: 10px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.devOptionsButton {
  border: #0b93f6;
  background-color: aliceblue;
}
.devOptionsButton:hover {
  border: #004b81;
  background-color: rgb(136, 162, 185);
}

.input-container {
  padding-left: 10px;
  display: flex;
  justify-content: left; /* Horizontally center the button */
  align-items: center; /* Vertically center the button */
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.speaker-icon {
  width: 24px;
  height: 24px;
  fill: #333;
}

.type-message-box {
  font-family: "Lato", sans-serif;
  padding: 8px;
  font-size: 16px;
  width: 100%;
  border-radius: 6px;
  line-height: 1.5; /* Adjust line height as needed */
  max-height: 200px; /* Set a maximum height if needed */
  resize: none; /* Disable manual resizing */
  overflow-y: hidden; /* Hide scrollbar until necessary */
  box-sizing: border-box; /* Include padding and border in the height calculation */
  margin-left: 5px;
}
</style>
