import api from "../utils/api";

export default {
  async sendMessage(message, includeTTS) {
    return await api.post(
      "chat/transcribe-and-respond",
      {
        transcribedText: message,
        includeTTS: includeTTS,
      },
      { responseType: "blob" }
    );
  },

  async transcribeInput(message) {
    return await api.post("chat/transcribe-input", {
      transcription: message,
    });
  },

  async getAssemblyTempToken() {
    return await api.get("auth/assembly-temp-token");
  },

  async moveToStep(stepId, includeTTS = true) {
    return await api.post("chat/set-step", { stepId, includeTTS });
  },
};
