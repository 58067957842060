<template>
  <div class="progress-container">
    <div class="line"></div>
    <div
      v-for="(step, index) in steps"
      :key="index"
      class="dot-container"
      :style="{ top: `${(index / (steps.length - 1)) * 100}%` }"
    >
      <div
        class="dot"
        :class="{
          completed: index < currentStep,
          current: index === currentStep,
        }"
        @mouseover="showTooltip(index)"
        @mouseleave="hideTooltip"
      >
        <svg
          v-if="index < currentStep"
          width="10"
          height="10"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="checkmark"
        >
          <path
            d="M20 6L9 17L4 12"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div v-if="tooltipIndex === index" class="tooltip-container">
          <div class="tooltip">
            {{ $t("Step") + " " + (index + 1) + ": " + step.name + "." }}
            <span
              class="tooltip-move-button circle-button-hover"
              @click="moveToStep(index)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 40 40"
                width="20"
                height="20"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <!-- Left triangle stays in the same position -->
                <polygon points="11 26 21 20 11 14 11 26"></polygon>

                <!-- Right triangle moved slightly to the right -->
                <polygon points="21 26 31 20 21 14 21 26"></polygon>

                <!-- The circular outline -->
                <circle
                  cx="20"
                  cy="20"
                  r="18"
                  stroke="currentColor"
                  stroke-width="2"
                  fill="none"
                ></circle>
              </svg>
            </span>
          </div>
        </div>
      </div>
      <div
        v-if="index < steps.length - 1"
        class="line-between"
        :class="{ completed: index < currentStep - 1 }"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
defineProps({
  steps: {
    type: Array,
    required: true,
  },
  currentStep: {
    type: Number,
    required: true,
  },
});
const emit = defineEmits(["move-to-step"]);
const tooltipIndex = ref(null);

const showTooltip = (index) => {
  tooltipIndex.value = index;
};

const hideTooltip = () => {
  tooltipIndex.value = null;
};

const moveToStep = (step) => {
  emit("move-to-step", step);
};
</script>

<style scoped>
.progress-container {
  position: relative;
  width: 50px;
  margin-bottom: 25px;
  margin-top: 10px;
}

@media (min-width: 600px) {
  .progress-container {
    margin-bottom: 165px;
    margin-top: 52px;
    margin-left: 15px;
  }
}

.line {
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: #ccc;
  left: 50%;
  transform: translateX(-50%);
}

.dot-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.dot {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #ccc;
  position: relative;
  cursor: pointer;
  z-index: 1;
  stroke: #fff;
  stroke-width: 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.dot.completed {
  background-color: green;
}

.dot.current {
  width: 15px;
  height: 15px;
  background-color: green;
}

.line-between {
  position: absolute;
  width: 8px;
  background-color: #ccc;
  left: 50%;
  transform: translateX(-50%);
  top: 12px;
  z-index: 0;
}

.line-between.completed {
  background-color: green;
}

.checkmark {
  margin-bottom: 2px;
}

.tooltip-container {
  position: absolute;
  padding-right: 28px;
  right: calc(
    100% - 20px
  ); /* Position the tooltip to the left of the dot with a 10px margin */
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
}

.tooltip {
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 10;
}

.circle-button-hover svg {
  transition: transform 0.2s ease, stroke 0.2s ease; /* Smooth transition */
}

.circle-button-hover:hover svg {
  transform: scale(1.1); /* Slightly enlarges the SVG on hover */
  color: #b5ffba;
}

.tooltip-move-button svg {
  vertical-align: middle; /* Optional: adjust vertical alignment if needed */
  margin-bottom: 2px;
}
</style>
