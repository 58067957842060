<template>
  <button :class="buttonClass" :disabled="disabled">
    <div v-if="variant == 'checkbox'">
      <span v-if="!isChecked">
        <!-- Unchecked square SVG -->
        <svg width="24" height="24" viewBox="0 0 24 24">
          <rect
            width="24"
            height="24"
            style="fill: none; stroke: currentColor; stroke-width: 2"
          />
        </svg>
      </span>
      <span v-if="isChecked">
        <!-- Checked square with checkmark SVG -->
        <svg width="24" height="24" viewBox="0 0 24 24">
          <rect
            width="24"
            height="24"
            style="fill: none; stroke: currentColor; stroke-width: 2"
          />
          <polyline
            points="4,12 10,18 20,6"
            style="fill: none; stroke: currentColor; stroke-width: 2"
          />
        </svg>
      </span>
    </div>
    <div v-else class="button-content">
      <slot></slot>
    </div>
  </button>
</template>

<script setup>
import { computed } from "vue";

// Define props for the component
const props = defineProps({
  variant: {
    type: String,
    default: "basic", // Default style class
  },

  disabled: {
    type: Boolean,
    default: false, // Default button state
  },
  isChecked: {
    type: Boolean,
    default: false, // Default button state
  },
});

// Compute dynamic class based on 'variant' and 'disabled' prop
const buttonClass = computed(() => {
  let baseClass =
    props.variant === "clean"
      ? `button-${props.variant}`
      : `button-basic button-${props.variant}`;

  // Add 'button-disabled' class if the button is disabled
  return props.disabled ? `${baseClass} button-disabled` : baseClass;
});
</script>

<style lang="scss">
@import "../styles/variables.scss";

.button-clean {
  all: unset;
  cursor: pointer;
  display: inline-block;
}

.button-checkbox {
  all: unset;
  cursor: pointer;
  display: inline-block;
  color: #0b93f6;
}

.button-basic {
  font-family: "Lato-Bold", sans-serif;
  font-size: 16px;
  padding: 10px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  background-color: $color-primary; /* #a060d1; #f46036;*/
  color: white;

  /* width: 100px; */
}
.button-basic:hover {
  background-color: $color-secondary; /* #a060d1; #f46036;*/
  cursor: grab;
}

.button-delete {
  background-color: red;
}

.button-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
