<template>
  <div class="avatar-buttons-container">
    <LinguaButton class="speaker-button" :variant="'clean'" @click="toggleTTS">
      <svg
        v-if="isSpeakerOn"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-volume-2"
      >
        <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
        <path d="M19.07 4.93a10 10 0 0 1 0 14.14"></path>
        <path d="M15.54 8.46a5 5 0 0 1 0 7.07"></path>
      </svg>
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-volume-x"
      >
        <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
        <line x1="23" y1="9" x2="17" y2="15"></line>
        <line x1="17" y1="9" x2="23" y2="15"></line>
      </svg>
    </LinguaButton>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
const store = useStore();
import { ref, onMounted } from "vue";
import LinguaButton from "./LinguaButton.vue";
const emit = defineEmits(["resume-audio", "stop-audio"]);
const isSpeakerOn = ref(false);
onMounted(async () => {
  isSpeakerOn.value = store.state.speakerOn;
});
const toggleTTS = () => {
  isSpeakerOn.value = !isSpeakerOn.value;
  store.commit("setSpeakerOn", isSpeakerOn.value);
  if (isSpeakerOn.value) {
    emit("resume-audio");
  } else {
    emit("stop-audio");
  }
};
</script>

<style>
.avatar-buttons-container {
  position: absolute;
  top: 10px; /* Position relative to the .threejs-container */
  right: 10px; /* This makes it placed in the top-right corner of the container */
}
.speaker-button {
  position: absolute;
  top: 0px; /* Position relative to the .threejs-container */
  right: 0px; /* This makes it placed in the top-right corner of the container */
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: white;
}
</style>
